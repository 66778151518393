<template>
  <div class="app-container">
    <nav>
      <div class="tips">
          <!-- <div class="text">开票点</div> -->
          <el-input v-model="queryValue" placeholder="请输入开票点名称"></el-input>
          <el-button @click="initTable" type="primary" class="btn" v-auth='"invoicingPoint:lookup"'>查询</el-button>
      </div>
      <div class="operation">
        <el-button @click="add" type="primary" class="btn"  v-auth='"invoicingPoint:add"' >+ 新增</el-button>
      </div>
    </nav>

    <div class="insurance_table" v-loading='isLoading'>
       
       <!-- <comps-table :tableData="tableData" :tableHeader="tableHeader" @multipleSelection="handleSelectionChange" :selectionType='true'  :total='total' :pageSize='form.rows' :page='form.page' @current-events="getInitList" @size-events='changeSize' class="comp_table" v-if="tableData.length" :pageSizeList='pageSizeList'>
      </comps-table> -->
      <comps-table :tableData="tableData" :tableHeader="tableHeader" @multipleSelection="handleSelectionChange" :selectionType='true'  :total='total' @current-events="savePage" @size-events='saveSize' class="comp_table" v-if="tableData.length">
      </comps-table>
      <nodata v-else message="暂无数据" class="no_data" />
    </div>

    <del-dialog :visible="delDialog" :cancelDialog="cancelDialog" :confirmDialog="confirmDialog" :isdeling="isdeling" :config="config">
      <div class="answer">亲，确认要删除吗？</div>
    </del-dialog>

   


  </div>
</template>

<script>
  import delDialog from '@/components/Dialog'
  import compsTable from "@/components/table/table";
  import { insurancesList, deleteInsurances } from '@/api/insurances'
  import { findPoint, delPoint } from '@/api/setInvoice'
  import tipsDialog from './dialog/showTips'
  export default {
    components: { delDialog ,compsTable,},
    data() {
      return {
        isLoading: false,
        hasData: false,
        tableHeader: [
          { prop: "no", label: "编号", center: 'left' ,
          render: (h, data) => {
              return (
                <span>
                  {data.row.no}
                </span>
              );
            },
          },
          {prop:'name',label:'开票点名称',center: 'left',},
          {prop:'remark',label:'备注',center: 'left',},
          {prop:'updateDate',label:'创建时间',center: 'left',},
          {
            label: "操作",
            center: 'center',
            width:'120',
            render: (h, data) => {
              return (
                  <span>
                    <el-button class='btn' type="text" v-auth="invoicingPoint:edit" onClick={() => { this.edit(data.row);}}>
                      编辑
                    </el-button>
                    <el-button class='btn' type="text" v-auth="invoicingPoint:delete" onClick={() => { this.delete(data.row);}}>
                      删除
                    </el-button>
                  </span>
                );
            },
          },
        ],
        queryValue:'',
        page: 1,
        tableData: [],
        total: 0,
        pageSize: 10,
        isdeling: false,
        multipleSelection:[],

        config: {
          top: '20vh',
          width: '16%',
          title: '提示',
          btnTxt: ["确定", "取消"]
        },
        // 保险id
        id: '',

        delDialog: false,
        importVis: false,
        exportVis:false,
        

      }
    },
    methods: {
      // getInitList(val) {  //设置当前页
      //   this.page = val;
      //   this.initTable(val);//修改分页的条数后，显示当前页的数据
      // },
      // changeSize(val) { //设置分页条数
      //   this.rows = val;
      //   this.initTable();
      // },
      savePage(val){
        this.page = val
        this.initTable()
      },
      saveSize(val) {
        this.pageSize = val
        this.initTable()
      },
      add() {
        // einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
        this.$router.push('invoicingPoint/add')
      },
      edit(row) {
        this.$router.push({
          path: 'invoicingPoint/edit',
          query: {
           id: row.id
          }
        })
      },
      

      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      
      //单选删除
      delete(row) {
        this.delDialog = true
        this.id = row.id
      },
      cancelDialog() { this.delDialog = false },

      confirmDialog() {
        this.isdeling = true
        this.$store.dispatch("delPoint", {
          ids: this.id,
          companyId : this.getToken('companyId'),
        }).then(res => {
          if (res.success) {
            einvAlert.success("提示",'删除成功')
            this.delDialog = false
            this.initTable()
          } else {
            einvAlert.error("提示",res.msg)
          }
          this.isdeling = false
        }).catch(err => {
          this.isdeling = false
        //  einvAlert.error("提示",err)
        })
      },
      
      // // 开票点列表
      // initTable(current) {
      //   this.isLoading = true;
      //   this.form.rows = this.rows;
      //   this.form.page = current ? current : this.page;
      //   let data = { name: this.queryValue, companyId: this.getToken('companyId'),...this.form };
      //   this.$store.dispatch("findPoint", data).then(res => {
      //      if (res.success) {
      //        this.isLoading = false;
      //        this.tableData = res.data;
      //        this.total = res.data.length;
      //       } else {
      //         this.isLoading = false;
      //         einvAlert.error("提示",res.msg)
      //       }
      //       }).catch(err => {
      //     })
        
      // },
      // 开票点列表
      initTable() {
        this.isLoading = true;
        this.$store.dispatch("findPoint", {
          name: this.queryValue,
          page: this.page,
          rows: this.pageSize,
          companyId : this.getToken('companyId'),
        }).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.tableData = res.data;
            this.total = res.data.length;
          } else {
            this.isLoading = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          einvAlert.error("提示",err)
          this.isLoading = false;
        })
      },

      imports(){
        this.importVis = true;
      },
      importCancel(){
        this.importVis = false;
      },
     handleExport(){
        this.exportVis = true;
     },
      exportCancel(){
        this.exportVis = false;
      },
      showTips(){
        this.promptVisible = true;
      },
      tipsCancel(){
        this.promptVisible = false;
      }
    },
    mounted() {
      this.initTable()
    }
  }
</script>

<style lang="less">
  nav{
    .text{
      font-size: 14px;
      width: 110px;
    }
    .tips{
      display: flex;
      align-items: center;
    }
    .btn{
      margin-left: 10px;
    }
  }
  .btn{
    margin-right: 10px;
  }
  .insurance_table {
    height: calc(100% - 35px);
    padding: 24px 20px;
    background-color: #fff;
    .comp_table {
      // border: 1px solid #ebeef5;
    }
  }
  // .answer {
  //   text-align: center;
  //   padding: 24px 0 14px;
  //   font-size: 16px;
  // }
</style>
